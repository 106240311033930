import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IDTOCaixa } from '@/models/DTO/Cadastros/Financeiro/IDTOCaixa';
import { ICaixa, ICaixaContaFechamento } from '@/models/Entidades/Cadastros/Financeiro/ICaixa';

/**
 * Serviço de Contas
 * Fornece todas regras de negócios e lógicas relacionado a caixa.
 */

class ServicoCaixa implements IServicoBase<ICaixa> {
    endPoint = 'caixas';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOCaixa): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(caixa: ICaixa): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, caixa);
      return result.data;
    }

    public async alterar(caixa: ICaixa): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${caixa.codigo}`, caixa);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ICaixa> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterContasFechamento(codigo: number, empresa: number): Promise<ICaixaContaFechamento[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/contas-fechamento/${codigo}/empresa/${empresa}`);
      return result.data;
    }
}
export default ServicoCaixa;
