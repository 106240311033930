
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IConta, IContaSaldoInicial, IContaUsuario } from '@/models/Entidades/Cadastros/Financeiro/IConta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import { ETipoConta } from '@/models/Enumeradores/Cadastros/Financeiro/ETipoConta';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import storeSistema from '@/store/storeSistema';
import { IContaBancaria } from '@/models/Entidades/Cadastros/Financeiro/IContaBancaria';
import { ICaixa, ICaixaContaFechamento } from '@/models/Entidades/Cadastros/Financeiro/ICaixa';
import ServicoCaixa from '@/servicos/Cadastros/Financeiro/ServicoCaixa';
import ServicoConta from '@/servicos/Cadastros/Financeiro/ServicoConta';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';

export default defineComponent({
  name: 'CaixaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    CampoNumerico,
    SelecionarEmpresa,
    SelecionarUsuario,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      preencherPermissoesDados, filtrarPermissaoDadosUsuario, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoCaixa = new ServicoCaixa();
    const servicoConta = new ServicoConta();
    servicoConta.requisicaoSistema();

    telaBase.identificadorRecurso = 'CADASTRO_CAIXAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_CAIXAS';

    const state = reactive({
      caixa: {} as ICaixa,
      usuarios: [] as number[],
      totalContasFechamento: 0,
    });

    function iniciarTela() {
      const caixa = {} as ICaixa;
      caixa.conta = {} as IConta;
      caixa.contasFechamento = [] as ICaixaContaFechamento[];
      caixa.conta.saldoInicial = {} as IContaSaldoInicial;
      caixa.conta.saldoInicial.codigoTipoDocumentoFinanceiro = 0;
      caixa.conta.saldoInicial.codigoPlanoContaCategoria = 0;
      caixa.codigo = 0;
      caixa.codigoTipoDocumentoFinanceiro = 0;
      caixa.saldoInicialPadrao = 0;
      caixa.fundoCaixaMinimo = 0;
      caixa.conta.codigo = 0;
      caixa.conta.tipo = ETipoConta.CaixaDiario;
      caixa.conta.codigoContaBancaria = 0;
      caixa.conta.ativo = true;
      caixa.conta.contasReceber = true;
      caixa.conta.contasPagar = true;
      caixa.conta.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      caixa.conta.contaBancaria = {} as IContaBancaria;
      state.caixa = caixa;
      state.usuarios = [] as number[];
      state.totalContasFechamento = 0;
    }

    function limparUsuarios() {
      if (!state.caixa.conta.usuariosEspecificos) {
        state.usuarios = [] as number[];
      }
    }

    function calcularTotalContasFechamento() {
      state.totalContasFechamento = 0;
      state.caixa.contasFechamento.forEach((t) => {
        state.totalContasFechamento += t.valor;
      });
    }

    async function adicionarContaFechamento() {
      const transferencia = {} as ICaixaContaFechamento;
      state.caixa.contasFechamento.push(transferencia);
    }

    function excluirContaFechamento(index: number) {
      state.caixa.contasFechamento.splice(index, 1);
      calcularTotalContasFechamento();
    }

    function validarContasFechamento() {
      let retorno = false;
      calcularTotalContasFechamento();
      state.caixa.contasFechamento.forEach((t) => {
        if ((t.codigoConta === 0 || t.codigoConta === undefined) && !retorno) {
          apresentarMensagemAlerta('Todos as contas devem ser informadas!');
          retorno = true;
        }
        if ((t.codigoPlanoContaCategoriaOrigem === 0 || t.codigoPlanoContaCategoriaOrigem === undefined) && !retorno) {
          apresentarMensagemAlerta('Todas as categorias de origem devem ser informadas!');
          retorno = true;
        }
        if ((t.codigoPlanoContaCategoriaDestino === 0 || t.codigoPlanoContaCategoriaDestino === undefined) && !retorno) {
          apresentarMensagemAlerta('Todas as categorias de destino devem ser informadas!');
          retorno = true;
        }
        if (t.valor === 0 || t.valor < 0) {
          apresentarMensagemAlerta('Todos os valores de transferências devem ser informado!');
          retorno = true;
        }
      });
      return retorno;
    }

    function obterUsuarios() {
      state.caixa.conta.usuarios = [] as IContaUsuario[];
      state.usuarios.forEach((u) => {
        const usuario = {} as IContaUsuario;
        usuario.codigoConta = state.caixa.conta.codigo;
        usuario.codigoUsuario = u;
        state.caixa.conta.usuarios.push(usuario);
      });
    }

    async function salvar(salvarNovo: boolean) {
      const retornoValidacao = validarContasFechamento();
      if (retornoValidacao) return;

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      obterUsuarios();
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoCaixa.incluir(state.caixa);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoCaixa.alterar(state.caixa);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          iniciarTela();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      iniciarTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, false);
        verificaConceitoParaApresentarEmpresas();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuario(state.caixa.conta.codigoEmpresa);

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          state.caixa = await servicoCaixa.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          state.caixa.contasFechamento = await servicoCaixa.obterContasFechamento(state.caixa.codigo, state.caixa.conta.codigoEmpresa);
          calcularTotalContasFechamento();
          if (state.caixa.conta.usuariosEspecificos) {
            state.caixa.conta.usuarios = await servicoConta.obterUsuarios(state.caixa.codigoConta, state.caixa.conta.codigoEmpresa);
            state.caixa.conta.usuarios.forEach((u) => {
              state.usuarios.push(u.codigoUsuario);
            });
          }
          telaBase.empresasSelecionadas = [];
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      state,
      modalBase,
      EPermissaoDados,
      ETipoConta,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      limparUsuarios,
      iniciarTela,
      salvar,
      calcularTotalContasFechamento,
      adicionarContaFechamento,
      excluirContaFechamento,
    };
  },
});
