
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import CaixaModal from './CaixaModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoCaixa from '@/servicos/Cadastros/Financeiro/ServicoCaixa';

export default defineComponent({
  name: 'Caixa',
  components: {
    TelaPadraoCrud,
    CaixaModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoCaixa = new ServicoCaixa();
    const refCrud = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = false;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do caixa';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, ordering: true,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'Descricao', position: 2, visible: true, ordering: true, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Ações', key: 'acoes', position: 3, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'Codigo', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrud.value !== undefined) {
        await refCrud.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }

    return {
      crudBase,
      servicoCaixa,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
      refCrud,
    };
  },
});
